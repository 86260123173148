import './bootstrap';

/**
 * Vite Livewire Plugin
 * --------------------
 * This plugin (made by def:studio) configures Vite for use with a Livewire setup,
 * allowing components hot reload without losing their state when their blade or class files change.
 *
 * @see https://github.com/defstudio/vite-livewire-plugin
 */
import { livewire_hot_reload } from 'virtual:livewire-hot-reload'
livewire_hot_reload();

/**
 * Livewire v3.X
 * -------------
 * Livewire is a full-stack framework for Laravel that makes building dynamic interfaces simple,
 * without leaving the comfort of Laravel.
 *
 * Note: Alpine.js is now included in Livewire v3.X
 * Note: Livewire will be initialized at the end of this file
 */
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

/**
 * Custom JS extensions
 * --------------------
 * - Chart.js @see https://www.chartjs.org/docs/latest/getting-started/integration.html
 * - Emojilib @see https://github.com/muan/emojilib
 * - PDF.js @see https://mozilla.github.io/pdf.js/ (commented out)
 * - Mark.js (keywords highlighting) @see https://markjs.io/
 */
import { Chart, registerables } from 'chart.js/auto';
Chart.register(...registerables);
window.Chart = Chart;

import emojilib from 'emojilib';
window.emojilib = emojilib;

import Mark from 'mark.js';
window.Mark = Mark;

/**
 * PDF viewer
 * ----------
 * - PDF.js is a Portable Document Format (PDF) library that is built with HTML5 @see https://mozilla.github.io/pdf.js/
 *
 * /!\ For some reason, an npm important doesn't work with the PDF.js library. It was added with a simple CDN import in the layout file.
 * <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.min.js"></script>
 * <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf_viewer.js"></script>
 */
// import * as pdfjsLib from "pdfjs-dist";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker?url";
// import { PDFPageView, EventBus, PDFLinkService, PDFFindController, PDFScriptingManager } from "pdfjs-dist/web/pdf_viewer";

// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// window.pdfjsLib = pdfjsLib;
// window.pdfjsWorker = pdfjsWorker;

import { pdfViewer } from './pdf-viewer';
window.pdfViewer = pdfViewer;

/**
 * Alpine.js extensions
 * --------------------
 * - Alpine UI @see https://alpinejs.dev/components
 * - Alpine Floating UI @see https://github.com/awcodes/alpine-floating-ui
 * - Clipboard @see https://github.com/ryangjchandler/alpine-clipboard
 * - Mousetrap @see https://alpinejs.dev/plugins/mousetrap
 * - Tooltip @see https://atomiks.github.io/tippyjs/
 */
import alpineUi from '@alpinejs/ui';
Alpine.plugin(alpineUi);

import Clipboard from "@ryangjchandler/alpine-clipboard"
Alpine.plugin(Clipboard)

import Mousetrap from '@danharrin/alpine-mousetrap'
Alpine.plugin(Mousetrap)

import AlpineFloatingUI from '@awcodes/alpine-floating-ui'
Alpine.plugin(AlpineFloatingUI)

import Tooltip from "@ryangjchandler/alpine-tooltip";
Alpine.plugin(Tooltip);

/**
 * Internal tools
 * --------------
 * - Copy to clipboard
 * - Dropdown search
 * - Fetch count
 */
import { copyToClipboard } from './copy-to-clipboard';
window.copyToClipboard = copyToClipboard;

import { dropdownSearch } from './dropdown-search';
window.dropdownSearch = dropdownSearch;

document.addEventListener('alpine:init', () => {
    Alpine.data('fetchCount', (url) => ({
        count: 0,
        fetchData() {
            fetch(url)
                .then(response => response.json())
                .then(data => this.count = data.subscriptionCount);
        },
        init() {
            this.fetchData();
        }
    }));
});

import fetchCount from './fetch-count';
document.addEventListener('alpine:init', () => {
    Alpine.data('fetchCount', fetchCount);
});

/**
 * Initialize Livewire
 * -------------------
 * Should be initialized after all Alpine.js plugins
 */
Livewire.start();
